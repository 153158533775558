const Happy = [
  "Ice Cream",
  "Chocolate Chips",
  "Strawberries",
  "Raspberries",
  "Blueberries",
  "Whipped Cream",
  "Caramel",
  "Caramel Sauce",
  "Toffee Popcorn",
  "Marshmallows",
  "Vanilla",
  "Vanilla Extract",
  "Honey",
  "Maple Syrup",
  "Cinnamon",
  "Nutmeg",
  "Cocoa",
  "Coconut Cream",
  "Condensed Milk",
  "Cream",
  "Creme Fraiche",
  "Greek Yogurt",
  "Full Fat Yogurt",
  "Butter",
  "Peanut Butter",
  "Almonds",
  "Pecans",
  "Walnuts",
  "Granulated Sugar",
  "Brown Sugar",
  "Dark Brown Sugar",
  "Light Brown Sugar",
  "Powdered Sugar",
  "Icing Sugar",
  "Digestive Biscuits",
  "Shortcrust Pastry",
  "Puff Pastry",
  "Gelatine Leafs",
];
const Tired = [
  "Eggs",
  "Bacon",
  "Bread",
  "Butter",
  "Cheese",
  "Cheddar Cheese",
  "Cream Cheese",
  "Greek Yogurt",
  "Full Fat Yogurt",
  "Avocado",
  "Bananas",
  "Almonds",
  "Cashews",
  "Walnuts",
  "Pecans",
  "Peanut Butter",
  "Honey",
  "Granola",
  "Oats",
  "Rolled Oats",
  "Dark Chocolate",
  "Milk Chocolate",
  "Coffee",
  "Tea",
  "Milk",
  "Orange Juice",
  "Apple Juice",
];
const Adventurous = [
  "Aubergine",
  "Balsamic Vinegar",
  "Basil",
  "Basmati Rice",
  "Bay Leaf",
  "Beef Brisket",
  "Beef Fillet",
  "Biryani Masala",
  "Borlotti Beans",
  "Cardamom",
  "Cashew Nuts",
  "Cumin",
  "Cumin Seeds",
  "Curry Powder",
  "Dark Soy Sauce",
  "Fennel Bulb",
  "Fennel Seeds",
  "Fenugreek",
  "Fish Sauce",
  "Flaked Almonds",
  "French Lentils",
  "Garam Masala",
  "Ginger",
  "Harissa Spice",
  "Jasmine Rice",
  "King Prawns",
  "Lamb",
  "Lamb Loin Chops",
  "Lamb Mince",
  "Mackerel",
  "Madras Paste",
  "Massaman Curry Paste",
  "Mint",
  "Monkfish",
  "Mustard Seeds",
  "Oxtail",
  "Paprika",
  "Red Chilli",
  "Red Chilli Powder",
  "Red Pepper Flakes",
  "Rice Vinegar",
  "Saffron",
  "Sesame Seed",
  "Shiitake Mushrooms",
  "Thai Fish Sauce",
  "Thai Green Curry Paste",
  "Thai Red Curry Paste",
  "Turmeric",
  "Turmeric Powder",
];
const Stressed = [
  "Cheese",
  "Cheddar Cheese",
  "Cream Cheese",
  "Butter",
  "Bacon",
  "Bread",
  "Potatoes",
  "Chicken",
  "Beef",
  "Pork",
  "Eggs",
  "Milk",
  "Cream",
  "Sour Cream",
  "Greek Yogurt",
  "Full Fat Yogurt",
  "Macaroni",
  "Tagliatelle",
  "Fettuccine",
  "Rice",
  "Broccoli",
  "Mushrooms",
  "Onions",
  "Garlic",
  "Tomatoes",
  "Tomato Sauce",
  "Chicken Stock",
  "Beef Stock",
  "Vegetable Stock",
  "Flour",
  "Sugar",
  "Brown Sugar",
  "Dark Brown Sugar",
  "Maple Syrup",
  "Honey",
  "Chocolate Chips",
  "Cocoa",
  "Ice Cream",
  "Peanut Butter",
  "Almonds",
  "Walnuts",
  "Pecans",
  "Cinnamon",
  "Nutmeg",
  "Vanilla",
  "Vanilla Extract",
];
const Focused = [
  "Noodles",
  "Rice Noodles",
  "Rice Stick Noodles",
  "Rice Vermicelli",
  "Jasmine Rice",
  "Basmati Rice",
  "Brown Rice",
  "Quinoa",
  "Spinach",
  "Kale",
  "Broccoli",
  "Carrots",
  "Red Pepper",
  "Yellow Pepper",
  "Green Pepper",
  "Shiitake Mushrooms",
  "Chestnut Mushroom",
  "Tofu",
  "Chicken Breast",
  "Salmon",
  "Tuna",
  "Eggs",
  "Avocado",
  "Olive Oil",
  "Garlic",
  "Ginger",
  "Soy Sauce",
  "Sesame Seed Oil",
  "Sesame Seed",
  "Green Beans",
  "Spring Onions",
  "Cilantro",
  "Basil",
  "Mint",
  "Lemon",
  "Lime",
  "Coconut Milk",
  "Thai Green Curry Paste",
  "Thai Red Curry Paste",
  "Fish Sauce",
  "Peanut Butter",
  "Cashew Nuts",
  "Almonds",
  "Walnuts",
];

export const moods = {
  Happy,
  Adventurous,
  Focused,
  Stressed,
  Tired,
};

export const cuisine = [
  "American",
  "British",
  "Canadian",
  "Chinese",
  "Croatian",
  "Dutch",
  "Egyptian",
  "Filipino",
  "French",
  "Greek",
  "Indian",
  "Irish",
  "Italian",
  "Jamaican",
  "Japanese",
  "Kenyan",
  "Malaysian",
  "Mexican",
  "Moroccan",
  "Polish",
  "Portuguese",
  "Russian",
  "Spanish",
  "Thai",
  "Tunisian",
  "Turkish",
  "Ukrainian",
  "Vietnamese",
];

export const categories = [
  "Beef",
  "Breakfast",
  "Chicken",
  "Dessert",
  "Goat",
  "Lamb",
  "Pasta",
  "Pork",
  "Seafood",
  "Side",
  "Starter",
  "Vegan",
  "Vegetarian",
];
